// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

@font-face {
  font-family: "unbounded regular"; /* Specify a name for your font */
  src: url("./Assets/fonts/unbounded-regular.ttf") format("woff2");

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins regular"; /* Specify a name for your font */
  src: url("./Assets/fonts/poppins_regular.ttf") format("woff2"); /* Adjust the path to your font file */
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "unbounded regular";
}

body {
  font-family: "poppins regular";
  margin: 0;
}
[class^="ant"] {
  /* Your CSS styles here */
  font-family: "poppins regular" !important;
}
input {
  font-family: "poppins regular" !important;
}
